import React from 'react'

import { Tooltip } from '@Azos-Seguros/apollo'
import { ListItem, Modal, useMediaQuery } from '@azos/shared'

import { BenefitDetialsMetatagProps } from './BenefitDetailsMetatag.props'
import { Button, Root } from './BenefitDetailsMetatag.styles'

const BenefitDetailsMetatag: React.VFC<BenefitDetialsMetatagProps> = ({
  className,
  id,
  icon,
  title,
  description,
  helpText,
}) => {
  const { isMobile } = useMediaQuery()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const EndIcon = React.memo(function endIcon() {
    if (helpText) {
      if (isMobile) {
        return (
          <i
            className="icon-circle-help"
            style={{ fontSize: '1.5rem' }}
            onClick={() => setIsModalOpen(true)}
          />
        )
      }

      return (
        <Tooltip title={helpText} position="top" maxWidth="300px">
          <i className="icon-circle-help" style={{ fontSize: '1.5rem' }} />
        </Tooltip>
      )
    }

    return <></>
  })

  return (
    <Root>
      <ListItem
        className={className}
        name={`list-item-${id}`}
        startIcon={<i className={icon} />}
        title={title}
        description={description}
        endIcon={<EndIcon />}
      />
      <Modal
        variant="bottomDrawer"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        showCloseButton={false}
      >
        {helpText}
        <Button
          onClick={() => setIsModalOpen(false)}
          fullWidth
          className="benefits-details-metatag__button"
        >
          Fechar
        </Button>
      </Modal>
    </Root>
  )
}

export default BenefitDetailsMetatag
