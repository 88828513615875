import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    background: ${theme.palette.neutral[50]};
    min-height: 102px;
    width: 100%;
    cursor: pointer;

    .news-banner-card {
      &__image-wrapper {
        display: none;

        @media (min-width: 330px) {
          display: initial;
          width: 120px;
          object-fit: fill;
        }
      }

      &__content {
        flex: 1;
        padding: ${theme.spacing[3]}px ${theme.spacing[4]}px;
        display: flex;
        flex-direction: column;

        &--header {
          width: 100%;
        }

        img {
          height: 22px;
          object-fit: scale-down;
          max-width: 100%;
          align-self: baseline;
        }

        &--title {
          font-weight: ${theme.typography.fontWeightBold};
          color: ${theme.palette.neutral[500]};
        }

        &--description {
          margin-top: 8px;
          display: block;
          color: ${theme.palette.neutral[500]};

          p {
            margin: 0;
          }
        }
      }
    }
  `,
)
