import React from 'react'

import { Typography, Button } from '@Azos-Seguros/apollo'
import { useAuth } from '@azos/account'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'

import { Modal, Root } from './ModalHomeWarning.styles'

const ModalHomeWarning = () => {
  const [open, setOpen] = React.useState(false)
  const { policies } = useMyPolicies()
  const { user } = useAuth()

  React.useEffect(() => {
    const alreadyShowModal = window.localStorage.getItem(
      '@azos/showModalHomeWarning',
    )

    const hasInEffectPolicy = !!policies.filter(p =>
      p.status.find(s => s.label === 'Ativa' || s.label === 'Em cancelamento'),
    ).length

    if (alreadyShowModal !== 'true' && hasInEffectPolicy) {
      setOpen(true)
      window.localStorage.setItem('@azos/showModalHomeWarning', 'true')
    }
  }, [policies])

  return (
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="445px">
      <Root>
        <Typography variant="h5" align="center">
          Queremos que sua experiência seja cada vez mais personalizada!
        </Typography>

        <Typography align="center">
          Responda a nossa pesquisa para entendermos melhor os motivos que
          levaram você a estar com a gente. Vamos lá?
        </Typography>

        <Button
          fullWidth
          onClick={() =>
            window.open(
              user?.isInsuredByBroker
                ? 'https://share.hsforms.com/1Ld5srIWgTiKf0Mb-i-wMOA5lbs1'
                : 'https://share.hsforms.com/1him6BouHS86ygP9pXqKozg5lbs1',
              '_blank',
            )
          }
        >
          Responder pesquisa
        </Button>
      </Root>
    </Modal>
  )
}

export default ModalHomeWarning
