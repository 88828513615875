import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    margin: ${theme.spacing[4]}px ${theme.spacing[3]}px;

    @media (min-width: ${theme.breakpoints.sm}px) {
      margin: 12px ${theme.spacing[4]}px;
    }

    .home {
      &__list-group {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        padding-bottom: ${theme.spacing[5]}px;

        @media (min-width: ${theme.breakpoints.lg}px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &__responsible-broker-content {
        margin: 26px 0;
      }

      &__broker-header {
        display: block;
        margin-bottom: 18px;
      }
    }

    .alert {
      margin: -32px -18px 48px;

      @media (min-width: 1024px) {
        margin: 0 0 48px;
      }

      .alert-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          max-width: 400px;
        }

        button {
          margin-left: 16px;
        }
      }
    }

    .no-policy {
      margin-bottom: 48px;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
    }
  `,
)

export const HeaderRoot = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing[4]}px 0 ${theme.spacing[2]}px;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing[3]}px;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      flex-direction: row;
      padding: ${theme.spacing[6]}px 0 ${theme.spacing[2]}px;

      & > div {
        width: 50%;

        &:not(:first-of-type) {
          margin-top: 0;
          margin-left: ${theme.spacing[4]}px;
        }
      }
    }
  `,
)
