import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    margin-top: 26px;
    margin-bottom: ${theme.spacing[3]}px;

    .coverages-list {
      &__header {
        display: flex;

        & > * + * {
          margin-left: ${theme.spacing[1]};
        }

        &--info-text {
          color: ${theme.palette.neutral[300]};
          flex-shrink: 0;
        }

        margin-bottom: ${theme.spacing[3]}px;
      }

      &__items {
        width: 100%;
        display: flex;

        & > * + * {
          margin-left: ${theme.spacing[2]}px;
        }

        & > * {
          flex-shrink: 0;
        }
      }
    }
  `,
)
