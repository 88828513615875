import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .important-info {
      &__title {
        display: block;
        color: ${theme.palette.neutral[50]};
        margin-bottom: ${theme.spacing[2]}px;

        strong {
          font-weight: ${theme.typography.fontWeightBold};
          color: ${theme.palette.primary.main};
        }
      }

      &__info {
        &--carousel {
          overflow-y: auto;
        }
        &--wrapper {
          display: flex;
          flex-direction: row;
          width: max-content;
          margin-top: 12px;

          & > div + div {
            margin-left: ${theme.spacing[3]}px;
          }

          @media (min-width: ${theme.breakpoints.md}px) {
            max-width: 100%;
          }
        }
      }
    }
  `,
)

export default Root
