import { Button as ApolloButton } from '@Azos-Seguros/apollo'
import { css, styled } from '@azos/shared'

export const Root = styled('div')(({ theme }) => css``)

export const Button = styled(ApolloButton)(
  ({ theme }) => css`
    margin-top: ${theme.spacing[4]}px;
  `,
)
