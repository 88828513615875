import React from 'react'

import { Typography } from '@Azos-Seguros/apollo'
import { Text } from '@azos/shared/src/components/v2'
import { marked } from 'marked'

import { NewsBannerCardProps } from './NewsBannerCard.props'
import { Root } from './NewsBannerCard.styles'

const NewsBannerCard: React.FCC<NewsBannerCardProps> = ({
  data,
  description,
  logo,
  image,
  logoText,
  onItemClick,
}) => {
  return (
    <Root onClick={() => onItemClick(data)}>
      <img
        src={image}
        alt={logoText ?? 'Card de Novidade'}
        className="news-banner-card__image-wrapper"
      />
      <div className="news-banner-card__content">
        <div className="news-banner-card__content--header">
          {logo ? (
            <img
              src={logo}
              alt="Logo da novidade"
              className="news-banner-card__content--logo-image"
            />
          ) : (
            <Text className="news-banner-card__content--title">{logoText}</Text>
          )}
        </div>
        <Typography
          className="news-banner-card__content--description"
          variant="caption"
          dangerouslySetInnerHTML={{
            __html: marked.parse(description),
          }}
        />
      </div>
    </Root>
  )
}

export default NewsBannerCard
