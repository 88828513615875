import { Box, BoxProps } from '@Azos-Seguros/apollo'
import { css, Modal as SharedModal } from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(SharedModal)(({ theme }) => css``)

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    h5 {
      margin-bottom: 24px;
    }

    button {
      margin-top: 32px;
    }
  `,
)
