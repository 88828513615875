import React from 'react'

import { useMediaQuery, withModal } from '@azos/shared'
import { BenefitDetails } from '@presentation/components/molecules/Benefits/BenefitDetails'

import { BenefitDetailsCardProps } from './BenefitDetailsCard.props'
import { Modal } from './BenefitDetailsCard.styles'

const BenefitDetailsCard: React.VFC<BenefitDetailsCardProps> = ({
  open,
  onClose,
  benefit,
  ...modalProps
}) => {
  const { isMobile } = useMediaQuery()

  if (!benefit) return null

  return (
    <Modal
      open={open}
      onClose={onClose}
      variant="drawer"
      title="Benefícios Azos"
      showBackButton={isMobile}
      showHeader={isMobile}
      backgroundColor="#F3F4F6"
      exitBgColor="#FFFFFF"
      noGutter
      {...modalProps}
    >
      {!isMobile && (
        <button type="button" onClick={onClose} className="benefit-exit-button">
          <span className="icon-exit" />
        </button>
      )}
      <BenefitDetails {...benefit} />
    </Modal>
  )
}

export default withModal<BenefitDetailsCardProps>(BenefitDetailsCard)
