import React from 'react'

import analytics from '@azos/analytics'
import { infoBanner } from '@azos/analytics/src/domain/models'
import { Carousel } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { DashboardSliderCardsType } from '@data/models'
import { useBanners } from '@presentation/providers/BannersProvider'

import { InfoCard } from './components'
import { Root } from './ImportantInfos.styles'

const ImportantInfo: React.VFC = () => {
  const { banners } = useBanners()

  if (banners.length === 0) return null

  const bannerByType = banners.map(item => item.type)

  const handlePosition = (pos: number) => {
    const events: {
      [key: string]: infoBanner
    } = {
      [DashboardSliderCardsType.READJUSTMENT_POLICY]:
        infoBanner.CHANGE_PAYMENT_TYPE,
      [DashboardSliderCardsType.CHANGE_PAYMENT_TYPE]:
        infoBanner.CHANGE_PAYMENT_TYPE,
      [DashboardSliderCardsType.OPEN_INVOICE]: infoBanner.OPEN_INVOICE,
      [DashboardSliderCardsType.GUARDIANS]: infoBanner.GUARDIANS,
      [DashboardSliderCardsType.BENEFICIARIES]: infoBanner.BENEFICIARIES,
      [DashboardSliderCardsType.ONBOARDING]: infoBanner.ONBOARDING,
      [DashboardSliderCardsType.DOWNLOAD_APP]: infoBanner.DOWNLOAD_APP,
    }

    analytics.dashboard.banner.viewImportantInfoBanner.execute({
      type: events[bannerByType[pos]],
    })
  }

  return (
    <Root>
      <Text variant="body2" className="important-info__title">
        Informações <strong>importantes</strong>
      </Text>

      <Carousel
        onPositionChanged={handlePosition}
        itens={banners.map(item => (
          <InfoCard key={item.type} item={item} />
        ))}
      />
    </Root>
  )
}

export default ImportantInfo
