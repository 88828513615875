import React from 'react'

import analytics from '@azos/analytics'
import { Carousel } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { ListBenefitResponse, ListNewsBannerData } from '@domain/models'

import { BenefitDetailsCard } from '../CardBenefits/components'
import { NewsBannerProps } from './NewsBanner.props'
import { Root } from './NewsBanner.styles'
import { NewsBannerCard } from './NewsBannerCard'

const NewsBanner: React.FCC<NewsBannerProps> = ({ itens }) => {
  if (itens && itens.length === 0) return null

  const [isBenefitDetailsOpen, setIsBenefitDetailsOpen] = React.useState(false)
  const [selectedBenefit, setSelectedBenefit] =
    React.useState<ListBenefitResponse | null>(null)

  const onItemClick = (data: ListNewsBannerData) => {
    if ('externalLink' in data) {
      window.open(data.externalLink, '_blank')
      return
    }

    if ('id' in data) {
      analytics.dashboard.benefits.view.benefitsBanner.execute({
        type: data.slug,
      })
      setSelectedBenefit(data as ListBenefitResponse)
      setIsBenefitDetailsOpen(true)
    }
  }

  return (
    <>
      <Root>
        <Text variant="body2" className="news-banner__title">
          Novidades para <strong>você</strong>
        </Text>

        <Carousel
          itens={itens.map((banner, index) => (
            <NewsBannerCard key={index} {...banner} onItemClick={onItemClick} />
          ))}
        />
      </Root>
      <BenefitDetailsCard
        open={isBenefitDetailsOpen}
        onClose={() => setIsBenefitDetailsOpen(false)}
        benefit={selectedBenefit}
      />
    </>
  )
}

export default NewsBanner
