import { css, Modal as SharedModal } from '@azos/shared'
import styled from '@emotion/styled'

export const Modal = styled(SharedModal)(
  ({ theme }) => css`
    .benefit-exit-button {
      cursor: pointer;
      background: #f3f4f6;
      border: 0;
      border-radius: 50%;
      font-size: 24px;
      padding: 0;
      position: relative;
      height: 48px;
      width: 48px;

      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 24px;
      top: 24px;

      span {
        font-size: 24px;
      }

      background: #f3f4f6;
      width: 48px;
      margin-left: auto;
    }
    .modal-header {
      margin: 0 !important;
      background-color: ${theme.palette.neutral[50]};
      padding: 12px;

      .title {
        margin-left: 4px;
      }
    }
  `,
)
