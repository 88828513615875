import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    .benefit-details-content {
      &__image {
        width: 100%;
      }

      &__wrapper {
        margin: ${theme.spacing[4]}px ${theme.spacing[3]}px;
      }

      &__metatag--item:not(first-of-type) {
        margin-top: 12px;
      }

      &__full--description {
        margin-top: ${theme.spacing[4]}px;

        p {
          margin-top: ${theme.spacing[4]}px;
          margin-bottom: ${theme.spacing[4]}px;
        }
      }

      &__alert {
        display: flex;
        align-items: center;

        margin-top: ${theme.spacing[4]}px;
        padding: ${theme.spacing[3]}px;
        background: ${theme.palette.neutral[200]};

        &--text {
          margin-left: 18px;
          font-weight: ${theme.typography.fontWeightBold};
        }
      }

      &__button--group {
        margin-top: ${theme.spacing[6]}px;
        display: flex;
        flex-direction: column;

        button {
          height: 48px;
        }

        button:not(:first-of-type) {
          background: ${theme.palette.neutral[50]};
          color: ${theme.palette.neutral[500]};
          border: none;
          margin-top: ${theme.spacing[3]}px;
        }
      }
    }
  `,
)
