import React from 'react'

import { Card } from '@Azos-Seguros/apollo'
import analytics from '@azos/analytics'
import { IBenefitsCodeService } from '@azos/analytics/src/data/services'
import { CardCopyText, useMediaQuery } from '@azos/shared'
import { Button, Text } from '@azos/shared/src/components/v2'
import { marked } from 'marked'

import { BenefitDetailsMetatag } from '../BenefitDetailsMetatag'
import { BenefitDetailsProps } from './BenefitDetails.props'
import { Root } from './BenefitDetails.styles'

const BenefitDetails: React.VFC<BenefitDetailsProps> = ({
  actions,
  alert,
  image,
  imageFormats,
  metatags,
  title,
  fullDescription,
  copy,
  slug,
}) => {
  const { isMobile } = useMediaQuery()
  const onClickExternalLink = (externalLink: string) => {
    if (externalLink) {
      window.open(externalLink, '_blank')
      handleClickExternalLink()
    }
  }

  const partnerExecuteEvent = (event: IBenefitsCodeService) => {
    if (slug) {
      event.execute({
        partner: slug,
      })
    }
  }

  const handleClickCardCopyText = () => {
    partnerExecuteEvent(analytics.dashboard.benefits.code.benefitsCode)
  }

  const handleClickExternalLink = () => {
    partnerExecuteEvent(analytics.dashboard.benefits.code.benefitsCodeAccess)
  }

  const imageContent = React.useMemo(() => {
    if (isMobile) {
      if (imageFormats?.small) {
        return imageFormats.small
      }

      return image
    }

    return image
  }, [image, imageFormats?.small, isMobile])

  return (
    <Root>
      <img
        src={imageContent}
        alt={title}
        className="benefit-details-content__image"
      />
      <div className="benefit-details-content__wrapper">
        {metatags?.map(item => (
          <BenefitDetailsMetatag
            className="benefit-details-content__metatag--item"
            key={item.id}
            id={item.id}
            icon={item.icon}
            title={item.title}
            description={item.value}
            helpText={item.helpText}
          />
        ))}
        <Text
          className="benefit-details-content__full--description"
          dangerouslySetInnerHTML={{
            __html: marked.parse(fullDescription ?? ''),
          }}
        />
        {copy && (
          <CardCopyText onClick={handleClickCardCopyText} value={copy ?? ''} />
        )}
        {alert && (
          <Card className="benefit-details-content__alert">
            <i className="icon-circle-warning" />
            <Text
              variant="body2"
              className="benefit-details-content__alert--text"
            >
              {alert}
            </Text>
          </Card>
        )}
        {actions && actions.length > 0 && (
          <div className="benefit-details-content__button--group">
            {actions.map(action => (
              <Button
                key={action.id}
                color={action.variant}
                onClick={() => onClickExternalLink(action.externalLink)}
              >
                {action.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Root>
  )
}

export default BenefitDetails
