import { css } from '@azos/shared'
import styled from '@emotion/styled'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;

    .news-banner {
      &__title {
        display: block;
        color: ${theme.palette.neutral[50]};
        margin-bottom: ${theme.spacing[2]}px;

        strong {
          font-weight: ${theme.typography.fontWeightBold};
          color: #00b000;
        }
      }
    }
  `,
)
