import React from 'react'

import { DraggableCarousel, Typography } from '@Azos-Seguros/apollo'
import { CoverageItem } from '@presentation/components/molecules/Coverages'

import { CoveragesListProps } from './CoveragesList.props'
import { Root } from './CoveragesList.styles'

const CoveragesList: React.VFC<CoveragesListProps> = ({
  coverages,
  onClickCoverage,
}) => {
  return (
    <Root>
      <div className="coverages-list__header">
        <Typography variant="body3">
          Saiba mais sobre os nossos <strong>produtos</strong>
        </Typography>
        <Typography
          variant="bodyBold3"
          className="coverages-list__header--info-text"
        >
          ・
        </Typography>
        <Typography
          variant="bodyBold3"
          className="coverages-list__header--info-text"
        >
          {coverages?.length || 6} produtos
        </Typography>
      </div>
      <DraggableCarousel>
        <div className="coverages-list__items">
          {coverages.map(coverage => (
            <CoverageItem
              key={coverage.productCode}
              coverage={coverage}
              onCoverageClick={onClickCoverage}
            />
          ))}
        </div>
      </DraggableCarousel>
    </Root>
  )
}

export default CoveragesList
