import React from 'react'

import { useAuth } from '@azos/account'
import { useGuardian } from '@presentation/providers/GuardianProvider'

import Home from './Home'

const HomeAdapter: React.FC<any> = ({ ...props }) => {
  const { user } = useAuth()
  const { isLoading: isLoadingGuardian, isGuardian } = useGuardian()

  return (
    <Home
      {...props}
      user={user}
      isLoadingGuardian={isLoadingGuardian}
      isGuardian={isGuardian}
    />
  )
}

export default HomeAdapter
